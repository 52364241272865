<template>
  <div class="text-center m-auto" style="margin-top: 25px !important">
    <img
      :class="{
        'mt-error-img-when-active':
          $parent.$parent.current_page_members &&
          $parent.$parent.current_page_members.length <= 0 &&
          $parent.$parent.isActive === true,
      }"
      alt="No member Found"
      class="error-img"
      src="../../../assets/images/no-member-found-new.svg"
    />
    <p class="error_text">Loading... Please Wait !</p>
  </div>
</template>

<script>
export default {
  name: "no-member-found",
};
</script>

<style scoped>
.error_text {
  font-size: larger;
  font-weight: 500;
  color: #a9bcdb;
  margin-top: 20px;
}

.error-img {
  height: 250px;
  width: 300px;
  margin-top: 50px;
}

@media (max-width: 1199px) {
  .mt-error-img-when-active {
    margin-top: -20px;
  }
}
</style>
